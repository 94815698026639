



















































































import {
	Component,
	Vue
} from "vue-property-decorator";
import LsDialog from "@/components/ls-dialog.vue";
import LsPagination from "@/components/ls-pagination.vue";
import ExportData from '@/components/export-data/index.vue'
import {
	apiArticleDelete,
	apiArticleLists,
	apiArticleIsShow,
} from "@/api/marketing/article";
import {
	RequestPaging
} from "@/utils/util";
import {
	PageMode
} from "@/utils/type";

@Component({
	components: {
		LsDialog,
		LsPagination,
		ExportData
	},
})
export default class ArticleLists extends Vue {
	/** S Data **/

	// 分页
	pager: RequestPaging = new RequestPaging();

	/** E Data **/

	/** S Methods **/

	// 更改显示开关状态
	changeStatus(value: 0 | 1, row: any) {
		apiArticleIsShow({
				id: row.id,
			})
			.then(() => {
				this.getArticleList()
			})
			.catch((err: any) => {
				console.log('err', err)
			});
	}

	// 新增资讯文章
	goArticleAdd() {
		this.$router.push({
			path: "/article/article_edit",
			query: {
				mode: PageMode["ADD"],
			},
		});
	}

	// 编辑资讯文章
	goArticleEdit(data: any) {
		this.$router.push({
			path: "/article/article_edit",
			query: {
				id: data.id,
				mode: PageMode["EDIT"],
			},
		});
	}

	// 获取资讯文章列表
	getArticleList() {
		this.pager
			.request({
				callback: apiArticleLists,
				params: {},
			})
			.catch((err: any) => {
				console.log('err', err)
			});
	}

	// 删除资讯文章
	onArticleDel(row: any) {
		apiArticleDelete({
			id: row.id
		}).then(() => {
			this.getArticleList();
		});
	}

	/** E Methods **/

	/** S Life Cycle **/
	created() {
		this.getArticleList();
	}

	/** E Life Cycle **/
}
